.login {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .bg {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background: url(../../resources/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;

    .blur {
      height: 100%;
      width: 100%;
      backdrop-filter: blur(2px) contrast(0.5);
    }
  }

  .paper {
    width: 25rem;
    padding: 2rem;
    background-color: #ddd;

    form {
      display: flex;
      flex-direction: column;
    }

    i {
      margin-top: 0.5rem;
      text-align: right;
      font-size: 0.8rem;
    }

    button {
      align-self: flex-end;
      margin-top: 3rem;
      width: 10rem;
    }
  }
}
