.editor-dialog {
  .MuiDialog-paper {
    max-width: 60%;
    width: 60%;
  }
  .MuiDialogTitle-root {
    padding-left: 0;
    padding-top: 0;
  }
}

.game-editor {
  display: grid;
  align-items: start;
  margin-bottom: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  grid-template-areas: 'name author'
    'type language'
    'link publisher'
    'players rating'
    'mechanics .';

  &--name {grid-area: name;}
  &--author {
    display: grid;
    grid-area: author;
    grid-template-columns: 1fr 67px;
    grid-gap: 1rem;

    button {
      align-self: center;
      margin-bottom: 1rem;
    }
  }
  &--players {
    display: grid;
    grid-area: players;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    grid-template-areas: 'label label'
      'min max';

    label {grid-area: label;}
    players-min {grid-area: min;}
    players-max {grid-area: max;}
  }
  &--rating {grid-area: rating;}
  &--type {grid-area: type;}
  &--mechanics {grid-area: mechanics;}
  &--link {grid-area: link;}
  &--publisher {grid-area: publisher;}
  &--language {grid-area: language;}

  &__controls {
    display: flex;
    align-self: flex-end;
    flex-direction: row-reverse;

    button {margin-left: 1rem;}
  }
}
