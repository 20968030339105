@import 'colors';

body {
  margin: 0;
  font-size: 14px;
  font-family: 'Roboto';
  color: $primary-text-dark;
  background-color: $main-background;
  font-family: 'Atla';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;

  h2 {
    margin: 0;
    margin-bottom: 1.5rem;
  }

  .main-container{
    display: flex;
    flex: 1;
    color: $secondary-text-dark;
    overflow: hidden;
  }
}

