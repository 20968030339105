.star-rating {
  display: inline-block;

  &:hover {cursor: pointer;}

  label {
    display: block;
    margin-bottom: 1rem;
  }
}
