// TODO: Resolve scss routes
@import '../colors';

.table {
  position: relative;
  overflow: hidden;
  display: grid;
  flex: 1;
  grid-template-rows: 1fr 60px;
}

.table-wrapper {
  overflow: auto;

  table {
    border-spacing: 0;
    border-bottom: 0;
    min-width: 100%;

    tr {height: 52px;}
    tr:last-child td {border-bottom: 0;}

    thead {
      th {
        position: sticky;
        top: 0;
        background-color: white;
        border-bottom: 2px solid $borders;
        z-index: 10;

        svg {
          height: 1rem;
          transform: translateY(2px);
        }
      }

      tr {height: 56px;}
    }

    th {
      text-align: left;
      &:last-child {border-right: 0;}
    }

    th,
    td {
      padding: 1rem;
      min-width: 80px;
      white-space: nowrap;
    }

    td {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td.cell__tags {
      overflow-x: auto;
      text-overflow: unset;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      .MuiChip-root { margin-right: 5px; }
    }

    tbody {
      td {
        background-color: white;
        border-bottom: 1px solid $borders;
      }
      tr:hover td {
        background-color: $hover-background;
      }
    }


    .cell {
      &__number {text-align: right;}
      &__action {
        text-align: center;
        span {
          pointer-events: none;
          cursor: pointer;
        }

        &--delete {
          &:hover,
          &:active,
          &:focus {color: $error;}
        }
      }
    }
  }
}

.table--filters {
  height: 60px;
  padding: 1rem;
  background: white;
  box-sizing: border-box;
  border-bottom: 1px solid $borders;

  .MuiSvgIcon-root {
    cursor: pointer;
  }

  .search-box { margin-top: -13px; }
}

.table--footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  padding-right: 1rem;
  border-top: 2px solid $borders;
  background-color: white;

  & > span {padding-right: 1rem;}
}
