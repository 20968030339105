@import 'colors';

.main-sidebar {
  width: 70px;
  min-width: 70px;
  background-color: $secondary-dark;
  color: $secondary-text-dark;
  transition: width 200ms ease-in-out, min-width 200ms ease-in-out;
  position: relative;

  ul {
    margin-top: 8px;
    padding: 0;
  }

  .item__title {
    pointer-events: none;
    opacity: 0;
    transition: opacity 160ms ease-in;
  }

  .sidebar__item {
    padding: 1rem 23px;
    padding-right: 0;
    border-radius: 3px;
    display: flex;
    align-items: center;
    list-style-type: none;
    white-space: nowrap;
    overflow: hidden;

    &:hover{
      background-color: $secondary-darker-20;
      color: $secondary-text-darker;
    }
    &--selected {color: $secondary-text-darker;}

    .material-icons {
      padding-right: 1rem;
      pointer-events: none;
    }
  }

  &--expanded {
    width: 220px;
    min-width: 220px;

    .item__title {opacity: 1;}

    .control svg {transform: rotate(180deg);}
  }

  &:hover .control {opacity: 1;}
}

.MuiPaper-root.control {
  position: absolute;
  background-color: $primary;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  top: 20px;
  right: -12px;
  opacity: 0;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
  
  svg {
    transition: transform 300ms ease-in-out;
  }
}
