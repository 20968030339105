.boardgames--wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  & button.new-button {
    position: absolute;
    top: -0.4rem;
    right: 0;
  }

  .table-wrapper {
    td {max-width: 250px;}
  }
}
