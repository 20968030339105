// https://material.io/resources/color/#!/?view.left=1&view.right=0&primary.color=698c3d&secondary.color=e1e674
$primary: #698c3d;
$primary-light: #99bc6a;
$primary-dark: #3b5f10;
$primary-text-light: black;
$primary-text-dark: white;

$secondary: #e1e674;
$secondary-light: #ffffa5;
$secondary-dark: #adb444;
$secondary-darker-20: #8a9036;
$secondary-text-light: #a0a0a0;
$secondary-text-dark: #3d3d3d;
$secondary-text-darker: #000;

$main-background: #ddd;
$hover-background: #eee;
$darker-background: #bbb;
$borders: #e0e0e0;

$error: #a10000; // red
$warning: #ebc100; // yellow
