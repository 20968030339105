.books-editor {
  display: grid;
  margin-bottom: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  grid-template-areas: 'title author'
    'desc desc'
    'genre type'
    'country lang'
    'date isbn'
    'rating link'
    'tags .';

  &--author {
    display: grid;
    grid-template-columns: 1fr 67px;
    grid-gap: 1rem;
    grid-area: author;

    button {
      align-self: center;
      margin-bottom: 1rem;
    }
  }
  &--title {grid-area: title;}
  &--description {grid-area: desc;}
  &--rating {grid-area: rating;}
  &--genre {grid-area: genre;}
  &--lang {grid-area: lang;}
  &--country {grid-area: country;}
  &--date {grid-area: date;}
  &--type {grid-area: type;}
  &--isbn {grid-area: isbn;}
  &--ddc {grid-area: ddc;}
  &--link {grid-area: link;}
  &--tags {grid-area: tags;}

  &__controls {
    display: flex;
    align-self: flex-end;
    flex-direction: row-reverse;

    button {margin-left: 1rem;}
  }
}
