.author-editor {
  display: flex;
  flex-direction: column;
  min-width: 20rem;

  &__controls {
    display: flex;
    align-self: flex-end;
    flex-direction: row-reverse;

    button {margin-left: 1rem;}
  }
}
