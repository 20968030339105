.books-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  & button.books-new-button {
    position: absolute;
    right: 0;
    top: -0.4rem;
  }

  .table-wrapper {
    table {margin: 0 auto;}
    td {max-width: 280px;}
  }
}
