@import 'colors';

.main-header {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 10px;
  align-items: center;
  background-color: $primary-dark;

  img {
    height: 30px;
    width: 30px;

    &:hover {cursor: pointer;}
  }

  h1 {padding-left: 20px;}
}
